main {
  height: 100%;
  display: flex;
  position: relative;
  padding: 1.5rem 0;

  @include sm {
    padding: 0;
  }

  mat-drawer-content {
    background-color: $color-background-light !important;
    overflow: hidden !important;

    @include sm {
      overflow-y: scroll !important;
    }
  }

  mat-drawer-container {
    height: 100%;
    width: 100%;
    color: var(--arc-font-color) !important;

    .sidenav {
      &__container {
        width: 32rem;
        height: 100%;
      }
    }

    .content {
      height: 100%;
      display: flex;
      background-color: $color-background-light;

      @include sm {
        height: auto;
      }

      &__summary {
        .summary-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      &__controls-container {
        min-width: 400px;
        border-right: 1px solid $color-line;
        justify-content: space-between;
        overflow-y: scroll;

        @include sm {
          min-width: auto;
          border-right: none;
        }
      }

      &__map-container {
        width: 100%;
        height: 100%;

        @include sm {
          min-height: 75vh;
          height: 75vh;
          margin: var(--arc-spacing-medium) 0;
        }
      }

      > * {
        padding: 0 1rem;
      }

      @include sm {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
