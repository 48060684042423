.controls-container {
  background-color: $color-background;
  padding: 1rem;
  .control-elements {
    padding: 0 1rem 1rem;
    arc-button {
      height: 40px;
      width: 127px;
    }

    @include lg {
      h2 {
        font-size: var(--arc-font-size-x-large);
      }
    }
  }
  .button-container {
    display: flex;
    gap: 0.5rem;
  }
  .apply-button-container {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    arc-button {
      height: 40px;
      width: 85px;
    }
    p {
      margin: 0;
      color: $color-apply-text;
    }
  }
}
