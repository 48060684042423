// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import './scale-palettes.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$scale-primary: mat.define-palette($scale-primary-palette);
$scale-accent: mat.define-palette($scale-accent-palette);

// The warn palette is optional (defaults to red).
$scale-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$scale-theme: mat.define-light-theme(
  (
    color: (
      primary: $scale-primary,
      accent: $scale-accent,
      warn: $scale-warn,
    ),
  )
);

$scale-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $scale-primary,
      accent: $scale-accent,
      warn: $scale-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($scale-theme);
@include mat.all-component-themes($scale-theme);

body * {
  --scale-background-color: var(--arc-grey-000);
  --scale-background-instructions-color: var(--arc-grey-010);
  --scale-background-dark: var(--arc-grey-020);
  --scale-button-color: var(--arc-grey-010);
  --scale-layer-item-state-color: var(--arc-grey-040);
}

body[theme='dark'] * {
  @include mat.core-theme($scale-dark-theme);
  @include mat.all-component-themes($scale-dark-theme);
  --scale-background-color: var(--arc-grey-090);
  --scale-background-dark: var(--arc-grey-060);
  --arc-color-secondary: var(--arc-grey-000);
  --scale-background-instructions-color: var(--arc-grey-100);
  --scale-layer-item-state-color: var(--arc-grey-020);
}
