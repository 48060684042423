.input__select {
  @include formatFormField;
  // make select fill cell space
  .mat-select-trigger {
    height: 100%;
  }
  // align the text in the field
  .mat-select-value-text {
    display: flex;
    align-items: center;
    margin-left: var(--arc-spacing-normal);
    height: 100%;
  }
  // override form field styles
  .mat-form-field-wrapper {
    padding: 0 !important;

    .mat-select {
      height: 100%;
    }
  }

  .mat-select-arrow-wrapper {
    background-color: $color-background-dark;
    padding: 0 8px;
    .mat-select-arrow {
      color: $color-select-arrow;
    }
  }

  &:focus-within {
    .mat-select-arrow-wrapper {
      border-color: $color-primary;
      .mat-select-arrow {
        color: $color-select-arrow !important;
      }
    }
    .mat-form-field-ripple {
      background-color: $color-primary !important;
    }
  }
}
