$spinnerSize: 160;
.spinner {
  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      color: $color-loading-text;
      font-size: 1.5rem;
    }
  }
  &__svg {
    width: $spinnerSize + px;
    height: $spinnerSize + px;
    -webkit-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    -moz-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    -ms-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    -o-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    -webkit-animation: spinner 1.5s linear infinite;
    -moz-animation: spinner 1.5s linear infinite;
    -ms-animation: spinner 1.5s linear infinite;
    -o-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360);
  }
}

@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360);
  }
}

@-ms-keyframes spinner {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360);
  }
}

@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
