.summary-container {
  background-color: $color-background;
  padding: 1rem;
  margin-top: 1.5rem;
  max-width: 400px;
  .text-container {
    padding: 0 1rem;
    h1,
    h2,
    p {
      margin: 0;
    }
    h2 {
      font-size: 1rem;
    }
  }

  @include sm {
    max-width: none;
  }
}
